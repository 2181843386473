import { Button, FormHeading } from "@lucio-erasmus/tfgj-components";
import React, { useRef } from "react";

const ResendOrderContainer = ({ onSubmit, order }) => {
  const inputEl = useRef(null);
  const selectEl = useRef(null);
  const onButtonClick = () => {
    console.log(inputEl);
    const values = {
      email: inputEl.current.value,
      template: selectEl.current.value,
      id: order._id,
    };

    onSubmit(values);
  };
  return (
    <>
      <FormHeading>Resend Order Email</FormHeading>
      <div className="form-group">
        <label>Email Address</label>
        <input
          ref={inputEl}
          name="email"
          className="form-control"
          defaultValue={order.shippingAddress.email}
        ></input>
      </div>
      <div className="form-group">
        <label>Template</label>
        <select defaultValue="pending" ref={selectEl} className="form-control">
          <option value="pending">Error completing order</option>
        </select>
      </div>
      <Button onClick={onButtonClick}>Resend Order Emails</Button>
    </>
  );
};

export default ResendOrderContainer;
