import React from "react";
import { withRouter } from "react-router-dom";

class LogoutContainer extends React.Component {
  handleLogout = e => {
    e.preventDefault();

    localStorage.removeItem("token");
    this.props.history.replace(`/`);
  };

  render() {
    return (
      <a href="#logout" onClick={this.handleLogout}>
        Logout
      </a>
    );
  }
}

export default withRouter(LogoutContainer);
